<template>
  <div class="tourist_complaint">
<!--    <NavBar title="投诉通道" class="nav_bottom"></NavBar>-->
    <main>
      <van-field v-model="cinema_name" label="影院名称" placeholder="请输入影院名称" input-align="right" />
      <van-field v-model="content" label="反映内容"  type="textarea" autosize rows="7" />
      <p class="uploader_tip">上传附件<span> (最多可上传5张图)</span></p>
      <van-uploader v-model="imgArr" :after-read="afterRead" :max-count="5" ></van-uploader>
      <van-field v-model="user_name" label="反映人姓名" placeholder="请填写姓名" />
      <van-field v-model="user_tel" label="联系方式" placeholder="请填写手机号码" />
      <p class="tel_tip">(该电话用于情况核实和反馈)</p>
    </main>
    <div class="submit">
      <button class="btn_sub" @click="sub">提交</button>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import { Field,Uploader,Toast } from 'vant';
import {Complaint, UploadingImg} from "../axios/api";
export default {
  name: "TouristComplaint",
  components:{
    NavBar,
    [Field.name]:Field,
    [Uploader.name]:Uploader
  },
  data(){
    return{
      cinema_name:'',
      content:'',
      user_name:'',
      user_tel:'',
      imgArr:[],
      loaderImgArr:[]
    }
  },
  methods:{
    afterRead(files) {
      files.status = 'uploading';
      files.message = '上传中...';
      //base64上传
      let {content} = files;
      UploadingImg({
        method:'base64',
        image:content.replace(/^data:image\/\w+;base64,/, '')
      }).then(respose=>{
        if(respose.state==="1"){
          files.status= 'done';
          files.message= '上传成功';
          this.loaderImgArr.push(respose.data)
        }else{
          files.status= 'failed';
          files.message= '上传失败'+respose?.msg;
        }
      }).catch(error=>{
        console.log(error)
      })

      /*let {file} = files;
      let data = new FormData();
      console.log(data.append)
      data.append("type", file);
      console.log(data,file)
      data.append("timestamp", Math.round(new Date().getTime() / 1000));
      console.log(data)*/

    },
    sub(){
      if(this.cinema_name){
        if(this.content){
          if(this.user_tel){
            let img = JSON.stringify(this.loaderImgArr);
            Complaint({
              member:this.cinema_name,
              content:this.content,
              phone:this.user_tel,
              images:img.substring(1,img.length-1).replace(/\"/g,""),
              name:this.user_name
            }).then(respose=>{
              if(respose.state==="1"){
                Toast.success("投诉成功")
                this.cinema_name=''
                this.content=''
                this.user_name=''
                this.user_tel=''
                this.imgArr=[]
                this.loaderImgArr=[]
              }else if(respose.state==="0"){
                Toast.fail("请输入正确的手机号")
              }else{
                Toast({message:"网络出现问题",icon:"cross"})
              }
            }).catch(error=>{
              console.log(error);
            })
          }else{
            Toast.fail("请输入手机号")
          }
        }else{
          Toast.fail("请输入\n反映内容")
        }
      }else{
        Toast.fail("请输入\n影院名称")
      }
    }
  }
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 51px*/
/*}*/
.tourist_complaint{
  padding-top: 51px;
}
main {
  width: 622px;
  min-height: 1197px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 0 auto;
  padding: 58px 33px 66px 35px;
}
main>>>.van-cell {
  flex-direction: column;
}
>>>.van-field__label{
  width: auto;
}
main>>>.van-cell .van-field__label {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
}
main>>>.van-cell .van-field__label:after {
  content: "*";
  width: 11px;
  height: 10px;
  color: #E71616;
}
main>>>.van-cell .van-field__value {
  font-size: 26px;
  font-family: PingFang SC-Bold, PingFang SC;
}
main>>>.van-cell:nth-of-type(1) {
  padding: 0 1px 20px 2px;
  height: 72px;
  line-height: 52px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #F2F2F2;
}
main>>>.van-cell:nth-of-type(1) .van-field__value {
  font-size: 30px;
  font-weight: bold;
  color: #343434;
}
main>>>.van-cell:nth-of-type(2) {
  padding: 56px 1px 0 0;
}
main>>>.van-cell:nth-of-type(2) .van-field__label {
  margin-bottom: 20px;
}
main>>>.van-cell:nth-of-type(2) .van-field__value {
  font-size: 30px;
  font-weight: bold;
  color: #343434;
  padding: 5px;
  border: 2px solid #F2F2F2;
  border-radius: 11px;
  line-height: 40px;
}
main>>>.van-cell:nth-of-type(4) {
  padding: 72px 1px 0 0;
}
main>>>.van-cell:nth-of-type(4) .van-field__label {
  line-height: 42px;
  margin-bottom: 24px;
}
main>>>.van-cell:nth-of-type(4) .van-field__label:after {
  content: "";
}
main>>>.van-cell:nth-of-type(4) .van-field__value {
  padding: 18px 22px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #D2D2D2;
  border: 2px solid #F2F2F2;
  border-radius: 11px;
  line-height: 36px;
}
main>>>.van-cell:nth-of-type(5) {
  padding: 40px 1px 0 0;
}
main>>>.van-cell:nth-of-type(5) .van-field__label {
  line-height: 42px;
  margin-bottom: 16px;
}
main>>>.van-cell:nth-of-type(5) .van-field__value {
  padding: 18px 22px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #D2D2D2;
  border: 2px solid #F2F2F2;
  border-radius: 11px;
  line-height: 36px;
}
main .tel_tip {

  height: 36px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #DCDCDC;
  line-height: 30px;
  margin: 8px 0 0 0;
}
main .uploader_tip {
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
  line-height: 35px;
  margin: 57px 0 20px 0;
}
main .uploader_tip span {
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #DCDCDC;
  width: 246px;
  height: 36px;
}
main>>>.van-uploader__upload {
  width: 156px;
  height: 156px;
  background: #F3F3F3;
  border-radius: 7px;
}
main>>>.van-uploader__upload .van-uploader__upload-icon {
  font-size: 38px;
}
main>>>.van-uploader__preview-image {
  width: 156px;
  height: 156px;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
  margin-top: 39px;
}
.submit .btn_sub {
  width: 691px;
  height: 89px;
  background: linear-gradient(270deg, #3054F8 0%, #4768FF 100%);
  box-shadow: 0px 0px 18px 3px rgba(66, 95, 220, 0.35);
  border-radius: 41px;
  opacity: 0.87;
  margin-top: 18px;
  border: 0;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}

</style>
